import React from "react";
import translator from "../../../Components/Extra/Translation/Translate";

export type Section = {
    title: string;
    subtitle: string;
    description: string;
    imageSrc: string;
    whySection: {
        title: string;
        items: Array<{
            title: string;
            description: string;
        }>;
    };
    offerSection?: {
        title: string;
        items: Array<{
            title: string;
            description: string;
        }>;
    };
    howToSection?: {
        title: string;
        steps: Array<{
            title: string;
            description: string;
        }>;
    };
    contactSection: {
        mainText: string;
        contactEmail: string;
        closingText: string;
    };
};

export type DiscoverComponentProps = {
    sections: Section[];
};

const DiscoverComponent: React.FC<DiscoverComponentProps> = ({ sections }) => {
    const { translate } = translator();
    return (
        <>
            {sections.map((section, index) => (
                <div
                    key={index}
                    className={`font-sans p-4 ${index === 0 ? "mt-20" : ""}`}
                >
                    <div className="md:max-w-6xl bg-slate-100 rounded-tr-[70px] max-w-lg mx-auto">
                        <div className="font-sans p-4">
                            <div
                                className="md:max-w-5xl max-w-xl mx-auto"
                                data-aos="fade-up"
                            >
                                <div className="grid md:grid-cols-2 gap-12">
                                    <div
                                        className="text-left"
                                        data-aos="fade-right"
                                    >
                                        <h2 className="text-kalypay-100 text-2xl font-bold mb-4">
                                            {section.title}
                                        </h2>
                                        <p className="mb-4 text-sm text-gray-500">
                                            {section.subtitle}
                                        </p>
                                        <p className="mb-4 text-sm text-gray-500">
                                            {section.description}
                                        </p>
                                    </div>
                                    <div
                                        className="max-h-72"
                                        data-aos="fade-left"
                                    >
                                        <img
                                            src={section.imageSrc}
                                            alt={`${section.title} Image`}
                                            className="rounded-lg object-contain w-full h-full"
                                        />
                                    </div>
                                </div>
                                <hr className="border-gray-300 my-5" />
                            </div>
                        </div>

                        <div
                            className="max-w-5xl max-md:max-w-xl mx-auto font-[sans-serif] my-4 px-3"
                            data-aos="fade-up"
                        >
                            <h2 className="text-kalypay-100 text-2xl font-bold mb-4">
                                {section.whySection.title}
                            </h2>
                            <div>
                                <div className="grid md:grid-cols-2 items-center gap-4">
                                    {section.whySection.items.map(
                                        (item, itemIndex) => (
                                            <div
                                                key={itemIndex}
                                                className="bg-white rounded-lg p-4"
                                                data-aos="zoom-in"
                                            >
                                                <h3 className="text-gray-800 text-[16px] font-bold mb-4">
                                                    {item.title}
                                                </h3>
                                                <p className="text-gray-600 text-sm">
                                                    {item.description}
                                                </p>
                                            </div>
                                        ),
                                    )}
                                </div>
                            </div>
                            <hr className="border-gray-300 my-12" />
                        </div>

                        {section.offerSection && (
                            <div
                                className="max-w-5xl max-md:max-w-xl mx-auto font-[sans-serif] my-4 px-3"
                                data-aos="fade-up"
                            >
                                <h2 className="text-kalypay-100 text-2xl font-bold mb-4">
                                    {section.offerSection.title}
                                </h2>
                                <div className="grid md:grid-cols-3 gap-4">
                                    {section.offerSection.items.map(
                                        (item, itemIndex) => (
                                            <div
                                                key={itemIndex}
                                                className="bg-white rounded-lg p-4 shadow-md"
                                                data-aos="flip-left"
                                            >
                                                <h3 className="text-gray-800 text-[16px] font-bold mb-4">
                                                    {item.title}
                                                </h3>
                                                <p className="text-gray-600 text-sm">
                                                    {item.description}
                                                </p>
                                            </div>
                                        ),
                                    )}
                                </div>
                                <hr className="border-gray-300 my-12" />
                            </div>
                        )}

                        {section.howToSection && (
                            <div
                                className="max-w-5xl max-md:max-w-xl mx-auto font-[sans-serif] my-4 px-3"
                                data-aos="fade-up"
                            >
                                <h2 className="text-kalypay-100 text-2xl font-bold mb-4">
                                    {section.howToSection.title}
                                </h2>
                                <div className="bg-white rounded-lg p-6 shadow-md">
                                    <ol className="list-decimal list-inside space-y-4">
                                        {section.howToSection.steps.map(
                                            (step, stepIndex) => (
                                                <li
                                                    key={stepIndex}
                                                    className="text-gray-800 font-semibold"
                                                    data-aos="fade-left"
                                                >
                                                    {step.title}
                                                    <p className="text-gray-600 text-sm mt-2">
                                                        {step.description}
                                                    </p>
                                                </li>
                                            ),
                                        )}
                                    </ol>
                                </div>
                                <hr className="border-gray-300 my-12" />
                            </div>
                        )}

                        <div
                            className="md:max-w-5xl max-w-xl mx-auto px-3"
                            data-aos="fade-up"
                        >
                            <div className="text-center">
                                <p className="mb-4 text-sm text-gray-500">
                                    {section.contactSection.mainText}
                                </p>
                                <p className="mb-4 text-sm text-gray-500">
                                    {translate("LANDING_DISCOVER", "contactus")}{" "}
                                    <span className="text-kalypay-100">
                                        {section.contactSection.contactEmail}
                                    </span>
                                </p>
                                <p className="text-sm text-gray-500">
                                    {section.contactSection.closingText}
                                </p>
                            </div>
                            <hr className="border-gray-300 my-12" />
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default DiscoverComponent;
