import { ChevronDown, ChevronRight } from "lucide-react";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

export type MenuItemProps = {
    path: string;
    label: string;
    children?: MenuItemProps[];
};

export default function MenuItem({ path, label, children }: MenuItemProps) {
    const location = useLocation();
    const hasChild = children && children.length > 0;

    const isActive =
        location.pathname === path ||
        (hasChild &&
            children.some((child) => location.pathname === child.path));

    return (
        <div className="group w-full relative  ">
            <NavLink
                to={path}
                className={`  space-x-2 flex justify-center items-center ${
                    isActive
                        ? "text-kalypay-100 md:!underline font-semibold underline-offset-[10px] decoration-[4px] "
                        : "hover:text-kalypay-100"
                }`}
            >
                <span>{label}</span>
                {hasChild && (
                    <div
                        className={`group-hover:rotate-180 ${
                            isActive ? "text-clisha-500" : ""
                        }`}
                    >
                        <ChevronDown size={16} />
                    </div>
                )}
            </NavLink>

            {hasChild && (
                <ul className="hidden group-hover:block md:!absolute bg-black p-5 rounded-lg space-y-1 w-max">
                    {children.map((child, index) => (
                        <li key={`child-item-${index}`}>
                            <NavLink
                                to={child.path}
                                className={({ isActive }) =>
                                    `hover:text-kalypay-100 px-4 py-2 space-x-2 flex justify-between ${
                                        isActive
                                            ? "text-kalypay-100 font-semibold"
                                            : ""
                                    }`
                                }
                            >
                                {child.label}
                            </NavLink>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
