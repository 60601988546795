import React from "react";
import DiscoverComponent, { Section } from "./discoverComponent";
import business from "../../images/discover/business.png";
import ambassador from "../../images/discover/ambassador.png";
import translator from "../../../Components/Extra/Translation/Translate";

const Discover: React.FC = () => {
    const { translate } = translator();
    const discoverData: Section[] = [
        {
            title: translate("LANDING_DISCOVER", "title"),
            subtitle: translate("LANDING_DISCOVER", "subtitle"),
            description: translate("LANDING_DISCOVER", "description"),
            imageSrc: `${business}`,
            whySection: {
                title: translate("LANDING_DISCOVER", "whySection_title"),
                items: [
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "whySection_title1",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "whySection_description1",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "whySection_title2",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "whySection_description2",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "whySection_title3",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "whySection_description3",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "whySection_title4",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "whySection_description4",
                        ),
                    },
                ],
            },
            contactSection: {
                mainText: translate(
                    "LANDING_DISCOVER",
                    "contactSection_mainText",
                ),
                contactEmail: "contact@kalypay.com",
                closingText: translate(
                    "LANDING_DISCOVER",
                    "contactSection_closingText",
                ),
            },
        },
        {
            title: translate("LANDING_DISCOVER", "title2"),
            subtitle: translate("LANDING_DISCOVER", "subtitle2"),
            description: translate("LANDING_DISCOVER", "description2"),
            imageSrc: `${ambassador}`,
            whySection: {
                title: translate("LANDING_DISCOVER", "whySection_title2_title"),
                items: [
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "whySection_title2_title1",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "whySection_title2_description1",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "whySection_title2_title2",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "whySection_title2_description2",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "whySection_title2_title3",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "whySection_title2_description3",
                        ),
                    },
                ],
            },
            offerSection: {
                title: translate("LANDING_DISCOVER", "offerSection_title"),
                items: [
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "offerSection_title1",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "offerSection_description1",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "offerSection_title2",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "offerSection_description2",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "offerSection_title3",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "offerSection_description3",
                        ),
                    },
                ],
            },
            howToSection: {
                title: translate("LANDING_DISCOVER", "howToSection_title"),
                steps: [
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "howToSection_title1",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "howToSection_description1",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "howToSection_title2",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "howToSection_description2",
                        ),
                    },
                    {
                        title: translate(
                            "LANDING_DISCOVER",
                            "howToSection_title3",
                        ),
                        description: translate(
                            "LANDING_DISCOVER",
                            "howToSection_description3",
                        ),
                    },
                ],
            },
            contactSection: {
                mainText: translate(
                    "LANDING_DISCOVER",
                    "contactSection_mainText2",
                ),
                contactEmail: "contact@kalypay.com",
                closingText: translate(
                    "LANDING_DISCOVER",
                    "contactSection_closingText2",
                ),
            },
        },
    ];

    return <DiscoverComponent sections={discoverData} />;
};

export default Discover;
