import { NavLink, useLocation } from "react-router-dom";
import translator from "../../Components/Extra/Translation/Translate";
import DropdownLanguage from "../../Front/Components/Theme/DropdownLanguage";
import ThemeToggle from "../../Front/Components/Theme/ThemeToggle";
import useAuthentication from "../../Services/Authentication/useAuthentication";
import RoutesNames from "../../Services/RoutesNames";
import UtilsService from "../../Services/UtilsService";
import MenuItem, { MenuItemProps } from "../components/nav/menu-item";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { LogIn, User2, UserPlus2, Logs, CircleX } from "lucide-react";

const Header = () => {
    const [sideNavWidthSide, setSideNavWidthSide] = useState("0px");
    const [icon, setIcon] = useState("menu");
    const { isAuthenticated } = useAuthentication();
    const { translate } = translator();
    const location = useLocation();

    const openNavSide = () => {
        setSideNavWidthSide("100vw");
        setIcon("close");
    };

    const closeNavSide = useCallback(() => {
        setSideNavWidthSide("0px");
        setIcon("menu");
    }, []);

    const menuItems: MenuItemProps[] = useMemo(() => {
        return [
            { path: "/", label: translate("HEADER_LANDING", "home") },
            {
                path: RoutesNames.About,
                label: translate("HEADER_LANDING", "about"),
            },
            {
                path: RoutesNames.IbanAndCard,
                label: translate("HEADER_LANDING", "card"),
            },
            {
                path: RoutesNames.Invest,
                label: translate("HEADER_LANDING", "invest"),
            },
            {
                path: RoutesNames.Discover,
                label: translate("HEADER_LANDING", "discover"),
            },
            {
                path: "#",
                label: translate("HEADER_LANDING", "ecosystem"),
                children: [
                    {
                        path: "/buys",
                        label: `Kalychain`,
                    },
                    {
                        path: "/exchanges",
                        label: "Kalyssi",
                    },
                    {
                        path: "/earns",
                        label: "Kalyswap",
                    },
                    {
                        path: "/earns",
                        label: "YASUKE",
                    },
                ],
            },
        ];
    }, [translate]);

    useEffect(() => {
        closeNavSide();
    }, [closeNavSide, location.pathname]);

    return (
        <div className="top-bar mdg-nav-bar mdg-theme-nav !inline-flex !fixed bg-black w-full !border-0 z-50">
            <nav
                aria-label="breadcrumb"
                className="-intro-x mr-auto flex flex-row w-full"
            >
                <NavLink
                    to="/"
                    className="active mdg-logo flex h-10 mb-2.5 w-full justify-center text-orange-500 hover:text-yellow-500"
                    aria-current="page"
                >
                    <img
                        src={UtilsService.getPulicImage(
                            "/dist/image/Logo-Kalypay-HD-Original-PNG.png",
                        )}
                        className="h-[50px] w-auto"
                        alt="Kalypay Logo"
                    />
                </NavLink>
            </nav>
            <div className="hidden text-white md:!flex items-center w-full">
                <ul className="flex justify-center justify-center gap-2  min-w-[630px]">
                    {menuItems.map((menu, index) => (
                        <MenuItem key={`menu-${index}`} {...menu} />
                    ))}
                </ul>
            </div>
            <div className="flex pr-[10px] md:!pr-[0px] w-full justify-end">
                {isAuthenticated ? (
                    <div className="intro-x relative mr-2 ">
                        <NavLink
                            className="btn-sm  items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm hidden md:!inline-flex"
                            to={RoutesNames.Assets}
                        >
                            <div className="hidden md:flex">
                                {translate("GENERAL", "MY_ACCOUNT")}
                                <span className="tracking-normal text-[#EEA913] group-hover:translate-x-0.5 transition-transform duration-150 ease-in-out ml-2 pt-[3px]">
                                    <svg
                                        className="fill-current"
                                        width="12"
                                        height="10"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path d="M1 6.002h7.586L6.293 8.295a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.416l-4-4a1 1 0 0 0-1.414 1.416l2.293 2.293H1a1 1 0 1 0 0 2Z" />
                                    </svg>
                                </span>
                            </div>
                        </NavLink>
                        <NavLink
                            to={RoutesNames.Assets}
                            className="flex md:hidden w-8 h-8 flex items-center justify-center hover:bg-slate-200 dark:bg-slate-700 dark:hover:bg-slate-600/80 rounded-full bg-slate-200"
                        >
                            <User2 />
                        </NavLink>
                    </div>
                ) : (
                    <>
                        <div className="intro-x relative mr-2 ">
                            <NavLink
                                className="btn-sm ml-2 hidden md:!inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
                                to={RoutesNames.Register}
                            >
                                {translate("GENERAL", "SIGNUP")}
                            </NavLink>
                        </div>
                        <div className="intro-x relative mr-2 ">
                            <NavLink
                                className="btn-sm hidden md:!inline-flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900 group shadow-sm"
                                to={RoutesNames.Login}
                            >
                                {translate("GENERAL", "SIGNIN")}
                            </NavLink>
                        </div>
                    </>
                )}
                <div className="intro-x relative mr-2  language">
                    <DropdownLanguage align="left" />
                </div>
                <div className="flex items-center justify-end">
                    <div className="intro-x dropdown mr-2 flex h-7 w-5 items-center">
                        <ThemeToggle />
                    </div>
                </div>
                <button
                    type="button"
                    onClick={
                        sideNavWidthSide === "0px" ? openNavSide : closeNavSide
                    }
                    aria-controls="navbarSupportedContent"
                    aria-label="Toggle navigation"
                    className="lg:!hidden block ml-3"
                >
                    {icon === "menu" ? (
                        <Logs size={30} className=" text-kalypay-100" />
                    ) : (
                        <CircleX size={34} className=" text-kalypay-100" />
                    )}
                </button>
            </div>
            {/* <!-- Sidebar Left navigation --> */}
            <aside>
                <div
                    className="lg:hidden fixed z-20 top-[60px] left-0 h-full w-0 bg-black overflow-hidden transition-all duration-500"
                    style={{
                        width: sideNavWidthSide,
                    }}
                >
                    <ul className="flex flex-col items-start space-y-2 my-10 text-white ">
                        {menuItems.map((menu, index) => (
                            <Fragment key={`m-menu-${index}`}>
                                <MenuItem {...menu} />
                                <hr className="w-full border-white/15 my-1" />
                            </Fragment>
                        ))}
                    </ul>
                    <div className="flex flex-row justify-center text-base items-center gap-5">
                        <NavLink
                            className="btn-sm flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900  shadow-sm"
                            to={RoutesNames.Register}
                        >
                            {translate("GENERAL", "SIGNUP")}
                        </NavLink>

                        <NavLink
                            className="btn-sm flex items-center text-slate-100 bg-slate-800 hover:bg-slate-900  shadow-sm"
                            to={RoutesNames.Login}
                        >
                            {translate("GENERAL", "SIGNIN")}
                        </NavLink>
                    </div>
                </div>
            </aside>
        </div>
    );
};

export default Header;
